@import url('../app/providers/theme-management/theme/fonts/general-sans/css/general-sans.css');

html,
body {
  margin: 0px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'GeneralSans', 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-tap-highlight-color: transparent;
}

.center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
